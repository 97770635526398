<template title="我的申请">
  <div class="container">
    <div class="bg-white p-2 px-4 radius-5 my-2" v-for="(sig,index) in data" :key="index">
      <router-link class="text-decoration-none" :to="'/jobs/info/' + sig.job.id">
        <div class="row border-bottom">
          <div class="col px-0 align-self-start">{{ sig.job.name }} <i class="text-muted">({{ APPLY_STATUS[sig.status] }})</i></div>
          <div class="col px-0 text-r">
            <span style="color: red">{{ SALARYINDEX[sig.job.salarys] }}</span>
          </div>
        </div>
      </router-link>
      <div class="row">
        <div class="col-8 mt-1 p-0">
          <p class="fs-8 m-0 text-muted">
            {{ JOBS_SCHOOLING[sig.job.schooling] }} | {{ AGEINDEX[sig.job.ages] }}
          </p>
          <p class="fs-8 m-0 text-truncate">
            <span class="bg-97 mx-1 text-muted" v-for="(wf, index) in sig.job.tags" :key="index">{{ WELFARE[wf] }}</span>
          </p>
          
        </div>

        <div class="col-4 p-0 pt-1 text-r">
          <p class="fs-8 m-0 text-muted">{{ sig.created_time }}</p>
        </div>
      </div>
      <div>
          <p class="fs-8 m-0 text-muted">
            {{ sig.job.interview_time}}
          </p>
      </div>

      <div class="row my-2">
        <div class="col-1 p-0">
          <img :src="sig.company.logo" width="35" />
        </div>

        <div class="col">
          <p class="m-0 fs-8">{{ sig.company.name }}</p>
          <p class="m-0 fs-7 text-muted">{{ NATURE[sig.company.nature] }} | {{ COMPANY_TYPE[type] }} | {{ sig.company.scale}}人</p>
        </div>
        <div class="col-3 p-0 fs-8 text-r pt-3 pr-1">
            联系驻厂
        </div>
      </div>
    </div>

    
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import Axios from 'axios'
import { APPLY_STATUS, SALARYINDEX, JOBS_SCHOOLING, AGEINDEX, WELFARE, NATURE, COMPANY_TYPE } from '@/lib/data'
const data = ref([])
const a = 2
for(let i=0; i<a; i++) {
  data.value.push(
    {
      id: 100, //投递ID
      status: 1, //状态 0待确认，1通知面试，2未通过，3在职，4合同期内离职，5旷工离职，6正常离职，7未报到，8面试通过未来
      created_time: '2022-02-30 15:30', //投递时间
      job: {
        id: 1001, //职位ID Number
        name: '人事经理', // 职位名称 Strying
        schooling: 1, //学历要求 Number <20
        salarys: 2, //综合薪资 Number <30
        ages: 2, //年龄要求 Number <30
        tags:[0,3,4], //职位优势  Array
        interview_time: '工作日下午2点-3点', //面试时间 Strying
      },
      company: {
        id: 100, //企业id number
        name: '一二一通信（苏州）有限公司', //企业名称 strying
        type: 1, //类型  生产加工 Number
        nature: 2, //性质 国企 number
        scale: 10000, //规模 10000人以上 number
        logo: '/imgs/h03.jpg' //企业Logo strying
      },
      in_factory: {
        id: 1001, //驻厂Id Number
        name: 'simon', //驻厂姓名 strying
        phone: '180180180180' //驻厂电话 strying
      }
    }
  )
}
onMounted(()=>{
  Axios.get('http://localhost:5000/user/jobs').then((response) => {
    if(response.data.code == 0) {
      data.value = response.data.data
    }
  })
})
</script>

<style>
</style>